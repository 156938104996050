import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { BaseView, OperationMode, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { ProfileC } from '@axks/components/lib/api/views/profile';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService, ConfirmationService, ConfirmEventType } from 'primeng/api';
import { ExcelNominaAuto } from 'src/app/api/excel/excel';
import { Nomina } from 'src/app/api/nomina/nomina';
import { NominaPsService } from 'src/app/procs/nomina/nomina-ps.service';
import { PeriodoPagoEnum } from '../../../enums/periodo-pago-enum.enum';

@Component({
  selector: 'axks-nomina-automatica-reg',
  templateUrl: './nomina-automatica-reg.component.html',
  styleUrls: ['./nomina-automatica-reg.component.scss']
})
export class NominaAutomaticaRegComponent extends BaseView implements OnInit, OnChanges {

  @Input() datosNomina: Nomina[] = [];
  @Input() selNomina: Nomina;
  @Input() idPeriodo: number;
  
  nomina: Nomina[] = [];
  idNominaGeneral: number;
  visibleDialog: boolean = false;
  profile = this.getProfile() as ProfileC;
  sumadoTotal: number;

  PR_PAGO = PeriodoPagoEnum;

  constructor(protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProvier: SessionClientProvider,
    private nominaClient: NominaPsService,
    private router: Router,
    private confirmationService: ConfirmationService) {
    super(screenModeService, deviceService, messageService, sessionProvier);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.datosNomina.length == 0) {
      this.sumadoTotal = 0;
    }
    else {
      for (let item of this.datosNomina) {
        this.sumadoTotal = this.sumadoTotal + item.importePago;
      }
    }
  }

  ngOnInit(): void {
    this.selNomina = {
      key: {},
      descuentos: []
    };

    this.sumadoTotal = 0;
  }

  quitarValorSeleccionado(event) {
    this.selNomina = {
      key: {},
      descuentos: []
    };
  }

  seleccionaRegistro(event) {
    this.visibleDialog = true;
  }

  exportExcel() {
    if (this.datosNomina.length != 0) {
      let registrosExcel = this.rellenarExcel(this.datosNomina);
      import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(registrosExcel);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "Nómina_Automática");
      });
    }
    else {
      this.messageService.add({
        summary: 'Atención',
        detail: 'No existe ningun elemento de nómina para exportar en un archivo excel.',
        severity: 'info'
      });
    }
  }

  saveAsExcelFile(buffer: any, fileName: string): void {

    import("file-saver").then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      let now = new Date();
      let mes = now.getMonth() + 1;
      FileSaver.saveAs(data, fileName + '-' + now.getFullYear() + "-" + (mes < 10 ? "0" + mes : mes) + "-" + now.getDate() +
        "-to-" + (now.getHours() < 10 ? "0" + now.getHours() : now.getHours()) +
        "-" + (now.getMinutes() < 10 ? "0" + now.getMinutes() : now.getMinutes()) +
        "-" + (now.getSeconds() < 10 ? "0" + now.getSeconds() : now.getSeconds()) + "-hrs" + EXCEL_EXTENSION);
    });
  }

  /* Función para llenar el excel*/
  rellenarExcel(items: Nomina[]): ExcelNominaAuto[] {
    let result: ExcelNominaAuto[] = [];

    for (let item of items) {
      let registroExcel = {
        numero_empleado: item.key.idEmpleado,
        apellido_paterno_empleado: item.apellidoPaterno,
        apellido_materno_empleado: item.apellidoMaterno,
        nombre_empleado: item.nombre,
        cuenta: item.cuentaAbono,
        importe: item.importePago,
        id_concepto: item.conceptoPago,
      } as ExcelNominaAuto;
      result.push(registroExcel);
    }
    return result;
  }

  autorizarNomina() {
    this.confirmationService.confirm({
      header: 'Confirmar nómina',
      message: '¿Desea confirmar la nómina?. Una vez generada se aplicaran los descuentos en turno.',
      icon: 'pi pi-exclamation-triangule',
      
      acceptLabel: 'Guardar nómina',
      acceptIcon: 'pi pi-save',
      
      rejectLabel: 'Cancelar',
      rejectButtonStyleClass: 'p-button-danger',

      accept: () => {
        this.registrarNomina();
      },
      reject: (type) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.infoMessage("Nómina no registrada", "Los descuentos aun no han sido aplicados");
            break;
          case ConfirmEventType.CANCEL:

            break;
        }
      }
    });

  }

  registrarNomina() {
    for (let dItem of this.datosNomina) {
      dItem.empleadoAutoriza = this.profile.idEmpleado;
    }
    if (this.datosNomina.length != 0) {
      this.showProgress = true;
      this.nominaClient.registrarNomina(this.datosNomina, this.profile.empresa).subscribe(
        (data) => {
          this.nomina = data;
          this.showProgress = false;
          this.idNominaGeneral = this.nomina[0].key.idNomina.id;
          this.messageService.add({
            severity: 'success',
            summary: 'Nómina generada correctamente',
            detail: 'Se genero de manera correcta la nómina.'
          });

          setTimeout(() => {
            this.redirectMain();
          }, 2000);
        },
        (error) => {
          this.showProgress = false;
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'No se pudo registrar la nómina.'
          });
        }
      );
    }
    else {
      this.messageService.add({
        summary: 'Atención',
        detail: 'No hay ningun elemento de nómina para registrar.',
        severity: 'warn'
      });
    }
  }

  redirectMain() {
    let profile = this.getProfile() as ProfileC;
    this.screenModeService.screenMode.next(OperationMode.SELECTED);
    this.router.navigate(["nomina-main"], {
      queryParams: {
        "e": profile.empresa,
        "a": profile.aplicacion,
        "s": profile.sesion,
        "idNomina": this.idNominaGeneral
      }
    });
  }

}
