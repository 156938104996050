import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { CommonModule, registerLocaleData } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import localeESMX from "@angular/common/locales/es-MX";
import { HttpClientModule } from '@angular/common/http';
import { CheckboxModule } from 'primeng/checkbox';
import { SliderModule } from 'primeng/slider';
import { MultiSelectModule } from 'primeng/multiselect';

import { ActionsBarModule, CommonsModule, DetailTabModule, OperationsBarModule, ResultListModule, SessionClientProvider } from '@axks/components';

import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DropdownModule } from 'primeng/dropdown';
import { ToastModule } from 'primeng/toast';
import { CardModule } from 'primeng/card';
import { PanelModule } from 'primeng/panel';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { MessageService } from 'primeng/api';
import { DialogModule } from 'primeng/dialog';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TooltipModule } from 'primeng/tooltip';
import { ProgressBarModule } from 'primeng/progressbar';
import { TreeTableModule } from 'primeng/treetable';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { TableModule } from 'primeng/table';
import { ToolbarModule } from 'primeng/toolbar';
import { ContextMenuModule } from 'primeng/contextmenu';

import { environment } from 'src/environments/environment';
import { RestClientModule } from '@axks/net';
import { HomeComponent } from './view/home/home.component';
import { CuentaFormComponent } from './view/cuenta/cuenta-form/cuenta-form.component';
import { CuentaComponent } from './view/cuenta/cuenta/cuenta.component';
import { KeyFilterModule } from 'primeng/keyfilter';
import { NominaFormComponent } from './view/nomina/nomina-form/nomina-form.component';
import { NominaComponent } from './view/nomina/nomina/nomina.component';
import { NominaMainComponent } from './view/nomina/nomina-main/nomina-main.component';
import { FormatoFechaPipe } from './pipe/formato-fecha/formato-fecha.pipe';
import { NominaManualProgFormComponent } from './view/nomina-manual/nomina-manual-prog/nomina-manual-prog-form/nomina-manual-prog-form.component';
import { NominaManualProgComponent } from './view/nomina-manual/nomina-manual-prog/nomina-manual-prog/nomina-manual-prog.component';
import { NominaManualRegComponent } from './view/nomina-manual/nomina-manual/nomina-manual-reg/nomina-manual-reg.component';
import { NominaManualFormComponent } from './view/nomina-manual/nomina-manual/nomina-manual-form/nomina-manual-form.component';
import { NominaManualMainComponent } from './view/nomina-manual/nomina-manual/nomina-manual-main/nomina-manual-main.component';
import { NominaAutomaticaRegComponent } from './view/nomina-automatica/nomina-automatica-reg/nomina-automatica-reg.component';
import { SelectButtonModule } from 'primeng/selectbutton';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

registerLocaleData(localeESMX, "es-MX");

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    CuentaFormComponent,
    CuentaComponent,
    NominaComponent,
    NominaFormComponent,
    NominaMainComponent,
    FormatoFechaPipe,
    NominaManualProgFormComponent,
    NominaManualProgComponent,
    NominaManualRegComponent,
    NominaManualFormComponent,
    NominaManualMainComponent,
    NominaAutomaticaRegComponent
  ],
  imports: [
    FontAwesomeModule,
    ConfirmDialogModule,
    TooltipModule,
    ProgressSpinnerModule,
    InputTextareaModule,
    ScrollPanelModule,
    RadioButtonModule,
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    InputTextModule,
    DropdownModule,
    HttpClientModule,
    TableModule,
    ToolbarModule,
    ActionsBarModule,
    OperationsBarModule,
    ResultListModule,
    DetailTabModule,
    ToastModule,
    CardModule,
    PanelModule,
    InputNumberModule,
    CalendarModule,
    BrowserModule,
    RestClientModule,
    CommonsModule,
    ButtonModule,
    CheckboxModule,
    SliderModule,
    DialogModule,
    MultiSelectModule,
    ContextMenuModule,
    ProgressBarModule,
    TreeTableModule,
    BrowserModule,
    KeyFilterModule,
    SelectButtonModule,
    InputSwitchModule
  ],
  providers: [
    MessageService, SessionClientProvider, ConfirmationService,
    { provide: "env", useValue: environment }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
