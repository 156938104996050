<div class="progress container" *ngIf="!loadContent || showProgress">
    <p-progressSpinner class="progress-spinner" styleClass="progress-spinner axks-progress-spinner"
        strokeWidth="8"></p-progressSpinner>
</div>

<!-- {{selEmpleado | json}} -->
<!-- {{datosNomina | json}} -->
<ng-container *ngIf="loadContent">
    <div class="p-grid">
        <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6" style="padding-left: 40px;">
            <h3>Nómina. Registro</h3>
        </div>
        <div class="p-sm-6 p-md-6 p-lg-6 p-xl-6 p-col-6"
            style="display: flex; justify-content: right; padding-right: 30px; padding-top: 30px;">
            <p-button type="button" (click)="regresarMain()" label="Buscar" icon="pi pi-search">
            </p-button>
        </div>
    </div>
    <div style="padding-left: 20px; padding-right: 20px;">
        <div class="p-grid" style="padding-left:10px;">
            <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12">
                <!-- <div style="display: flex; align-items: center; justify-content: center;"> -->
                    <div style="display: flex; align-items: center; justify-content: center;">
                    <p-inputSwitch [(ngModel)]="nominaAutomatica" [style]="{'display': 'flex'}" (onChange)="limpiarValores()"></p-inputSwitch>
                    <span *ngIf="nominaAutomatica" style="font-size: 0.8rem">&nbsp;&nbsp;Nómina automática</span>
                    <span *ngIf="!nominaAutomatica" style="font-size: 0.8rem;">&nbsp;&nbsp;Nómina manual</span>
                    </div>
                    <!-- <p-selectButton [options]="modosNomina" [(ngModel)]="nominaAutomatica" optionLabel="label" optionValue="value"></p-selectButton> -->
                <!-- </div> -->
            </div>
            <div class="p-sm-12 p-md-2 p-lg-2 p-xl-2 p-col-12">
                <!-- <br> -->
                <span class="p-float-label">
                    <p-dropdown inputId="fl-tcnm" [(ngModel)]="idPeriodo" [options]="catPeriodosPago"
                        optionLabel="periodoPago" optionValue="id" [autoDisplayFirst]="false" autoWidth="true"
                        [style]="{'width':'95%'}" showClear="true">
                    </p-dropdown>
                    <label for="fl-tcnm">Periodo de pago</label>
                </span>
            </div>
            <div class="p-sm-12 p-md-10 p-lg-8 p-xl-8 p-col-12">
                <!-- <br> -->
                <button pButton type="button" icon="fa fa-upload" label="Cargar datos" (click)="cargarDatos($event)"
                    [disabled]="showProgress"></button>&nbsp;&nbsp;
                <button pButton type="button" icon="fa fa-trash" label="Limpiar datos" (click)="limpiarPantalla()"></button>
            </div>
        </div>

        <br>
        <h5>Lista de empleados</h5>
        <div *ngIf="nominaAutomatica">
            <axks-nomina-automatica-reg [datosNomina]="datosNominaAuto" [selNomina]="selNomina" [idPeriodo]="idPeriodo">
            </axks-nomina-automatica-reg>
        </div>
        <div *ngIf="!nominaAutomatica">
            <axks-nomina-manual-reg [datosNomina]="datosNominaManual" [idPeriodo]="idPeriodo">
            </axks-nomina-manual-reg>
        </div>
    </div>
</ng-container>
<p-toast></p-toast>