<div class="p-grid frm-part">
    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12" *ngIf="allowFormFields('nombreEmpleado')">
        <span class="p-float-label">
            <input type="text" id="fl-nomEmp" pInputText [(ngModel)]="item.nombreEmpleado">
            <label for="fl-nomEmp" class="label-full"><span *ngIf="operationMode != op.SEARCH" style="color: red;">* </span>Nombre de
                empleado</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12" *ngIf="allowFormFields('tipoCuenta')">
        <span class="p-float-label">
            <input type="text" id="fl-tpCuenta" pInputText [(ngModel)]="item.tipoCuenta">
            <label for="fl-tpCuenta" class="label-full"><span *ngIf="operationMode != op.SEARCH" style="color: red;">* </span>Tipo de
                cuenta</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12" *ngIf="allowFormFields('cuenta')">
        <span class="p-float-label">
            <input type="text" id="fl-cuenta" pInputText [(ngModel)]="item.cuentaAbono" pKeyFilter="pint">
            <label for="fl-cuenta" class="label-full"><span *ngIf="operationMode != op.SEARCH" style="color: red;">* </span>Cuenta</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12" *ngIf="allowFormFields('importe')">
        <span class="p-float-label">
            <p-inputNumber inputId="fl-importe" [(ngModel)]="item.importe" mode="currency" currency="MXN" locale="es-MX"
                [min]="1"></p-inputNumber>
            <label for="fl-importe" class="label-full"><span *ngIf="operationMode != op.SEARCH" style="color: red;">*
                </span>Importe</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12" *ngIf="allowFormFields('periodoPago')">
        <span class="p-float-label">
            <p-dropdown inputId="fl-periodo" [autoDisplayFirst]="false" [options]="periodos"
                [(ngModel)]="item.idPeriodo.id" optionLabel="periodoPago" optionValue="id"></p-dropdown>
            <label for="fl-periodo" class="label-full"><span *ngIf="operationMode != op.SEARCH" style="color: red;">* </span>Periodo de
                pago</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12" *ngIf="allowFormFields('bancoReceptor')">
        <span class="p-float-label">
            <p-dropdown inputId="fl-banc-recp" [autoDisplayFirst]="false" [options]="bancos"
                [(ngModel)]="item.idBancoReceptor.id" optionLabel="bancoReceptor" optionValue="id" filter="true"
                filterBy="bancoReceptor"></p-dropdown>
            <label for="fl-banc-recp" class="label-full"><span *ngIf="operationMode != op.SEARCH" style="color: red;">* </span>Banco
                receptor</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12" *ngIf="allowFormFields('plazaBanxico')">
        <span class="p-float-label">
            <input type="text" id="fl-plaza-banx" pInputText [(ngModel)]="item.plazaBanxico">
            <label for="fl-plaza-banx" class="label-full"><span *ngIf="operationMode != op.SEARCH" style="color: red;">* </span>Plaza
                banxico</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12"
        *ngIf="operationMode == op.ADDNEW || operationMode == op.EDIT">
        <span style="color: red; font-size: 0.7rem;">* Campos requeridos</span>
    </div>
</div>