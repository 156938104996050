import { Component, Input, OnInit } from '@angular/core';
import { BaseView, OperationMode, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { NominaManualProg } from '../../../../api/nomina-manual/nomina-manual';
import { BancoReceptor } from '../../../../api/catalogos/banco-receptor';
import { BancoReceptorPsService } from '../../../../procs/catalogos/banco-receptor/banco-receptor-ps.service';
import { PeriodoPagoPsService } from '../../../../procs/catalogos/periodo-pago/periodo-pago-ps.service';
import { PeriodoPago } from 'src/app/api/catalogos/periodo-pago';

@Component({
  selector: 'axks-nomina-manual-prog-form',
  templateUrl: './nomina-manual-prog-form.component.html',
  styleUrls: ['./nomina-manual-prog-form.component.scss']
})
export class NominaManualProgFormComponent extends BaseView implements OnInit {

  @Input() item: NominaManualProg;
  @Input() operationMode: OperationMode;

  op = OperationMode;
  bancos: BancoReceptor[];
  periodos: PeriodoPago[];

  constructor(protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProvier: SessionClientProvider,
    private clientBancos: BancoReceptorPsService,
    private clientPeriodo: PeriodoPagoPsService) {

    super(screenModeService, deviceService, messageService, sessionProvier);
  }

  ngOnInit(): void {
    if (!this.item) {
      this.item = {
        idBancoReceptor: {}
      };
    }

    this.initCatBancosReceptores();
    this.initCatPeriodosPago();
  }

  initCatBancosReceptores() {
    this.clientBancos.all().subscribe(
      (data) => {
        this.bancos = data;
      },
      (error) => {
        this.errorMessage("Error", "No se pudo obtener el catalogo de bancos receptores");
      }
    );
  }

  initCatPeriodosPago() {
    this.clientPeriodo.all().subscribe(
      (data) => {
        this.periodos = data;
      },
      (error) => {
        this.errorMessage("Error", "No se pudo obtener el catalogo de periodos de pago");
      }
    );
  }

}
