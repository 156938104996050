<div class="p-grid frm-part">
    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12" *ngIf="allowFormFields('idEmpleadoField')">
        <br>
        <span class="p-float-label">
            <p-dropdown id="fl-emp" [options]="catEmpleados" [(ngModel)]="item.key.idEmpleado"
                optionLabel="nombreCompleto" optionValue="id" [filter]="true" filterBy="nombreCompleto"
                [autoDisplayFirst]="false" autoWidth="false" [style]="{'width':'80%'}">
            </p-dropdown>
            <label for="fl-emp" class="label-full"><span *ngIf="operationMode != op.SEARCH" style="color: red;">* </span>Empleado</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12" *ngIf="allowFormFields('numeroCuentaField')">
        <br>
        <span class="p-float-label">
            <input id="fl-numcta" type="text" pInputText pKeyFilter="int" [(ngModel)]="item.numeroCuenta"
                style="width: 80%;">
            <label for="fl-numcta" class="label-full"><span *ngIf="operationMode != op.SEARCH" style="color: red;">* </span>Cuenta o CLABE</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12" *ngIf="allowFormFields('idBancoReceptorField')">
        <br>
        <span class="p-float-label">
            <p-dropdown id="fl-banrec" [options]="catBancoReceptor" [(ngModel)]="item.idBancoReceptor.id"
                optionLabel="bancoReceptor" optionValue="id" [autoDisplayFirst]="false" autoWidth="false"
                [style]="{'width':'80%'}" filterBy="bancoReceptor" [filter]="true">
            </p-dropdown>
            <label for="fl-banrec" class="label-full"><span *ngIf="operationMode != op.SEARCH" style="color: red;">* </span>Banco
                Receptor</label>
        </span>

    </div>

    <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12" *ngIf="allowFormFields('tipoCuentaField')">
        <br>
        <span class="p-float-label">
            <p-dropdown for="fl-tpcta" [options]="catTipoCuenta" [(ngModel)]="item.idTipoCuenta.id"
                optionLabel="tipoCuenta" optionValue="id" [autoDisplayFirst]="false" autoWidth="false"
                [style]="{'width':'80%'}">
            </p-dropdown>
            <label for="fl-tpcta" class="label-full"><span *ngIf="operationMode != op.SEARCH" style="color: red;">* </span>Tipo de
                Cuenta</label>
        </span>
    </div>

    <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12" *ngIf="operationMode != op.SEARCH && operationMode != op.SELECTED">
        <br>
        <br>
        <span style="color: red; font-size: 0.7rem;">* Campos requeridos</span>
    </div>
</div>