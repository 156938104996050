import { version } from '../../package.json';

export const environment = {
  production: false,
  dev: false,
  uat: true,
  version: version,

  appId: "nomina-gui",
  empresas: [
    { id: 1, domain: 'uat.axkansoluciones.com' },
    { id: 2, domain: 'uat.sbsynergy.mx' },
    { id: 3, domain: 'uat.habitataxkan.com.mx' }
  ],

  msBancoReceptor: "https://nomina.uat-ms.axkans.org/nomina/banco-receptor",
  msTipoCuenta: "https://nomina.uat-ms.axkans.org/nomina/tipo-cuenta",
  msCuentaEmpleado: "https://nomina.uat-ms.axkans.org/nomina/cuenta-empleado",
  msPeriodoPago: "https://nomina.uat-ms.axkans.org/nomina/periodo-pago",
  msEmpleado: "https://rh.uat-ms.axkans.org/rh/empleado/ps",
  msNomina: "https://nomina.uat-ms.axkans.org/nomina/nomina",
  msTipoOperacion: "https://nomina.uat-ms.axkans.org/nomina/tipo-operacion",
  msEmpresa: "https://pers.uat-ms.axkans.org/personas/company",
  msNominaCat: "https://nomina.uat-ms.axkans.org/nomina/nomina-catologo",
  msNominaManualProg: "https://nomina.uat-ms.axkans.org/nomina/nomina-manual-prog",
  msNominaManual: "https://nomina.uat-ms.axkans.org/nomina/nomina-manual",
  msNominaManualCat: "https://nomina.uat-ms.axkans.org/nomina/nomina-manual-catalogo",

  sprofile: "https://sec.uat-ms.axkans.org/autentica/profile",
  theme: {
    default: 'https://static.uat-gui.axkans.org/portal/themes/blue-gray/styles/theme.css'
  }
}