import { Injectable } from '@angular/core';
import { BaseProcess } from '@axks/components';
import { NominaRestcService } from '../../client/nomina/nomina-restc.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Nomina } from '../../api/nomina/nomina';

@Injectable({
  providedIn: 'root'
})
export class NominaPsService extends BaseProcess<NominaRestcService>{

  client: NominaRestcService;

  constructor(httpClient: HttpClient, crest: NominaRestcService) {
    super(httpClient, crest);
    this.client = crest;
  }

  cargarDatosNomina(idPeriodo: number): Observable<Nomina[]> {
    return this.client.cargarDatosNomina(idPeriodo);
  }

  registrarNomina(items: Nomina[], idEmpresa: number): Observable<Nomina[]> {
    return this.client.registrarNomina(items, idEmpresa);
  }
}
