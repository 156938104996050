<div class="progress container" *ngIf="showProgress">
    <p-progressSpinner class="progress-spinner" styleClass="progress-spinner axks-progress-spinner"
        strokeWidth="8"></p-progressSpinner>
</div>

<p-confirmDialog></p-confirmDialog>

<div>
    <!-- {{selNomina | json}} -->
    <p-table [value]="datosNomina" class="result-list result" [paginator]="true" [rows]="25" [resizableColumns]="true"
        [showCurrentPageReport]="true" currentPageReportTemplate="{first} - {last} de {totalRecords}"
        [rowsPerPageOptions]="[25,50,100,{showAll:'Todos'}]">

        <ng-template pTemplate="caption">
            <div class="p-d-flex">
                <button type="button" pButton pRipple icon="pi pi-save" label="Autorizar nómina"
                    (click)="autorizarNomina()" class="p-mr-2" [disabled]="showProgress"></button>
                <button type="button" pButton icon="pi pi-file-excel" iconPos="left" pTooltip="Generar excel"
                    (click)="exportExcel()" style="margin-right: 0.5em;" class="p-button-success p-ml-auto"></button>
            </div>
        </ng-template>

        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="nombreEmpleado">Nombre del empleado <p-sortIcon field="nombreEmpleado">
                    </p-sortIcon>
                </th>
                <th pSortableColumn="tipoCuenta">Tipo de cuenta <p-sortIcon field="tipoCuenta"></p-sortIcon>
                </th>
                <th pSortableColumn="cuentaAbono">Cuenta de abono <p-sortIcon field="cuentaAbono"></p-sortIcon>
                </th>
                <th pSortableColumn="importe">Importe <p-sortIcon field="importe"></p-sortIcon>
                </th>
                <th pSortableColumn="idBancoReceptor.bancoReceptor">Banco receptor <p-sortIcon
                        field="idBancoReceptor.bancoReceptor"></p-sortIcon>
                </th>
                <th pSortableColumn="plazaBanxico">Plaza Banxico <p-sortIcon field="plazaBanxico"></p-sortIcon>
                </th>
            </tr>

            <tr>
                <th colspan="3" class="p-text-right">Total</th>
                <th>{{sumadoTotal | currency}}</th>
                <th colspan="2"></th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-itemNomina>
            <tr [pSelectableRow]="itemNomina">
                <td>
                    <span class="p-column-title">Nombre del empleado</span>
                    {{itemNomina.nombreEmpleado}}
                </td>
                <td>
                    <span class="p-column-title">Tipo de cuenta</span>
                    {{itemNomina.tipoCuenta}}
                </td>
                <td>
                    <span class="p-column-title">Cuenta de abono</span>
                    {{itemNomina.cuentaAbono}}
                </td>
                <td>
                    <span class="p-column-title">Importe</span>
                    {{itemNomina.importe | currency}}
                </td>
                <td>
                    <span class="p-column-title">Banco receptor</span>
                    {{itemNomina.idBancoReceptor.bancoReceptor}} - {{itemNomina.idBancoReceptor.cvTransferencia}}
                </td>
                <td>
                    <span class="p-column-title">Plaza Banxico</span>
                    {{itemNomina.plazaBanxico}}
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>