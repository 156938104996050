import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './view/home/home.component';
import { CuentaComponent } from './view/cuenta/cuenta/cuenta.component';
import { NominaComponent } from './view/nomina/nomina/nomina.component';
import { NominaMainComponent } from './view/nomina/nomina-main/nomina-main.component';
import { NominaManualProgComponent } from './view/nomina-manual/nomina-manual-prog/nomina-manual-prog/nomina-manual-prog.component';
import { NominaManualMainComponent } from './view/nomina-manual/nomina-manual/nomina-manual-main/nomina-manual-main.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'cuenta', component: CuentaComponent },
  { path: 'nomina', component: NominaComponent },
  { path: 'nomina-main', component: NominaMainComponent },
  { path: 'nomina-manual-main', component: NominaManualMainComponent },
  { path: 'nomina-manual-prog', component: NominaManualProgComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
