import { Injectable } from '@angular/core';
import { BaseProcess } from '@axks/components';
import { NominaManualCatRestcService } from 'src/app/client/nomina-manual/nomina-manual-cat-restc.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NominaManualCatPsService extends BaseProcess<NominaManualCatRestcService>{

  constructor(httpClient: HttpClient, crest: NominaManualCatRestcService) {
    super(httpClient, crest);
  }
}
