import { Component, OnInit } from '@angular/core';
import { PeriodoPago } from '../../../api/catalogos/periodo-pago';
import { Nomina } from '../../../api/nomina/nomina';
import { BaseView, OperationScreenModeService, SessionClientProvider, OperationMode } from '@axks/components';
import { ProfileC } from '@axks/components/lib/api/views/profile';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { PeriodoPagoPsService } from '../../../procs/catalogos/periodo-pago/periodo-pago-ps.service';
import { NominaPsService } from '../../../procs/nomina/nomina-ps.service';
import { Router } from '@angular/router';
import { NominaManualProg } from '../../../api/nomina-manual/nomina-manual';
import { NominaManualProgPsService } from '../../../procs/nomina-manual-prog/nomina-manual-prog-ps.service';

@Component({
  selector: 'axks-nomina',
  templateUrl: './nomina.component.html',
  styleUrls: ['./nomina.component.scss']
})
export class NominaComponent extends BaseView implements OnInit {

  datosNominaAuto: Nomina[] = [];
  datosNominaManual: NominaManualProg[] = [];
  catPeriodosPago: PeriodoPago[];
  idPeriodo: number;
  profile = this.getProfile() as ProfileC;
  visibleCamposGenerales: boolean;
  visibleDialog: boolean = false;
  nominaReg: Nomina[];
  showProgress: boolean;
  idNominaGeneral: number;
  selNomina: Nomina;
  modosNomina: any[];
  nominaAutomatica: boolean = true;
  urlParams = new URLSearchParams(window.location.search);

  constructor(protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProvier: SessionClientProvider,
    private periodoPagoClient: PeriodoPagoPsService,
    private nominaClient: NominaPsService,
    private nominaManualProgClient: NominaManualProgPsService,
    private router: Router
  ) {
    super(screenModeService, deviceService, messageService, sessionProvier);

    if (this.urlParams.get('nomina-manual')) {
      this.nominaAutomatica = false;
    }
  }

  ngOnInit(): void {
    this.consultarPeriodoPago();
    if (!this.selNomina) {
      this.selNomina = {
        key: {}
      };
    }
    this.modosNomina = [
      { label: 'Nómina Automática', value: true },
      { label: 'Nómina Manual', value: false }
    ];
  }

  consultarPeriodoPago() {
    let filtro = {} as PeriodoPago;
    this.periodoPagoClient.find(filtro).subscribe(
      (data) => {
        this.catPeriodosPago = data;
      },
      (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'No se pudo obtener los periodos de pago'
        });
      }
    );
  }

  limpiarPantalla() {
    this.datosNominaAuto = [];
    this.datosNominaManual = []
    this.idPeriodo = undefined;
    this.selNomina = {
      key: {}
    };
  }

  limpiarValores() {
    this.datosNominaAuto = [];
    this.datosNominaManual = [];
  }

  cargarDatos(event) {
    this.datosNominaAuto = [];
    this.datosNominaManual = [];
    //this.visibleCamposGenerales = true;
    if (this.idPeriodo == undefined || this.idPeriodo == null) {
      this.messageService.add({
        severity: 'info',
        summary: 'Atencion',
        detail: 'Se debe seleccionar un periodo de pago valido para cargar la información.'
      });
    }
    else {
      if (this.nominaAutomatica == true) {
        this.showProgress = true;
        this.nominaClient.cargarDatosNomina(this.idPeriodo).subscribe(
          (data) => {
            this.showProgress = false;
            this.datosNominaAuto = data;
          },
          (error) => {
            this.showProgress = false;
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'No se pudo obtener los datos de la nómina automática.'
            });
          }
        );
      }
      else {
        this.showProgress = true;
        let filtro = {
          activa: true,
          idPeriodo: {
            id: this.idPeriodo
          }
        } as NominaManualProg;
        this.nominaManualProgClient.find(filtro).subscribe(
          (data) => {
            this.datosNominaManual = data;
            this.showProgress = false;
          },
          (error) => {
            this.showProgress = false;
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'No se pudo obtener los datos de la nómina manual.'
            });
          }
        );
      }
    }
  }

  regresarMain() {
    let profile = this.getProfile() as ProfileC;
    this.screenModeService.screenMode.next(OperationMode.SEARCH);
    if (this.nominaAutomatica == true || this.nominaAutomatica == null) {
      this.router.navigate(["nomina-main"], {
        queryParams: {
          "e": profile.empresa,
          "a": profile.aplicacion,
          "s": profile.sesion
        }
      });
    }
    else {
      this.router.navigate(["nomina-manual-main"], {
        queryParams: {
          "e": profile.empresa,
          "a": profile.aplicacion,
          "s": profile.sesion
        }
      });
    }
  }

  redirectMain() {
    let profile = this.getProfile() as ProfileC;
    this.screenModeService.screenMode.next(OperationMode.SELECTED);
    this.router.navigate(["nomina-main"], {
      queryParams: {
        "e": profile.empresa,
        "a": profile.aplicacion,
        "s": profile.sesion,
        "idNomina": this.idNominaGeneral
      }
    });
  }
}
