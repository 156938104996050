import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { BaseView, OperationMode, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService, ConfirmationService, ConfirmEventType } from 'primeng/api';
import { NominaManualProgPsService } from '../../../../procs/nomina-manual-prog/nomina-manual-prog-ps.service';
import { NominaManualProg, NominaManual } from '../../../../api/nomina-manual/nomina-manual';
import { ExcelNominaManual } from 'src/app/api/excel/excel';
import { NominaManualPsService } from '../../../../procs/nomina-manual/nomina-manual-ps.service';
import { ProfileC } from '@axks/components/lib/api/views/profile';

@Component({
  selector: 'axks-nomina-manual-reg',
  templateUrl: './nomina-manual-reg.component.html',
  styleUrls: ['./nomina-manual-reg.component.scss']
})
export class NominaManualRegComponent extends BaseView implements OnInit, OnChanges {

  @Input() datosNomina: NominaManualProg[] = [];
  nominasManuales: NominaManual[];
  idNominaGeneral: number;
  profile = this.getProfile() as ProfileC;
  sumadoTotal: number;

  constructor(protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProvier: SessionClientProvider,
    private clientNominaManual: NominaManualPsService,
    private router: Router,
    private confirmationService: ConfirmationService) {
    super(screenModeService, deviceService, messageService, sessionProvier);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.datosNomina.length == 0) {
      this.sumadoTotal = 0;
    }
    else {
      for (let item of this.datosNomina) {
        this.sumadoTotal = this.sumadoTotal + item.importe;
      }
    }
  }

  ngOnInit(): void {
    this.sumadoTotal = 0;
  }

  exportExcel() {
    if (this.datosNomina.length != 0) {
      let registrosExcel = this.rellenarExcel(this.datosNomina);
      import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(registrosExcel);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "Nómina_Manual");
      });
    }
    else {
      this.messageService.add({
        summary: 'Atención',
        detail: 'No existe ningun elemento de nómina para exportar en un archivo excel.',
        severity: 'info'
      });
    }
  }

  saveAsExcelFile(buffer: any, fileName: string): void {

    import("file-saver").then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      let now = new Date();
      let mes = now.getMonth() + 1;
      FileSaver.saveAs(data, fileName + '-' + now.getFullYear() + "-" + (mes < 10 ? "0" + mes : mes) + "-" + now.getDate() +
        "-to-" + (now.getHours() < 10 ? "0" + now.getHours() : now.getHours()) +
        "-" + (now.getMinutes() < 10 ? "0" + now.getMinutes() : now.getMinutes()) +
        "-" + (now.getSeconds() < 10 ? "0" + now.getSeconds() : now.getSeconds()) + "-hrs" + EXCEL_EXTENSION);
    });
  }

  /* Función para llenar el excel*/
  rellenarExcel(items: NominaManualProg[]): ExcelNominaManual[] {
    let result: ExcelNominaManual[] = [];

    for (let item of items) {
      let registroExcel = {
        nombre_empleado: item.nombreEmpleado.toUpperCase(),
        tipo_cuenta: item.tipoCuenta,
        cuenta_abono: item.cuentaAbono,
        importe: item.importe,
        banco_receptor: item.idBancoReceptor.cvTransferencia,
        plaza_banxico: item.plazaBanxico
      } as ExcelNominaManual;
      result.push(registroExcel);
    }
    return result;
  }

  autorizarNomina() {
    this.confirmationService.confirm({
      header: 'Confirmar nómina',
      message: '¿Desea confirmar la nómina?',
      icon: 'pi pi-exclamation-triangule',
      
      acceptLabel: 'Guardar nómina',
      acceptIcon: 'pi pi-save',
      
      rejectLabel: 'Cancelar',

      accept: () => {
        this.registrarNomina();
      },
      reject: (type) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.infoMessage("Nómina no registrada", "La nómina aun no ha sido registrada");
            break;
          case ConfirmEventType.CANCEL:

            break;
        }
      }
    });

  }

  registrarNomina() {
    if (this.datosNomina.length != 0) {
      this.showProgress = true;
      this.clientNominaManual.registrarNomina(this.datosNomina, this.profile.empresa, this.profile.idEmpleado).subscribe(
        (data) => {
          this.nominasManuales = data;
          this.showProgress = false;
          this.idNominaGeneral = this.nominasManuales[0].key.idNominaManual.id;
          this.messageService.add({
            severity: 'success',
            summary: 'Nómina generada correctamente',
            detail: 'Se genero de manera correcta la nómina.'
          });

          setTimeout(() => {
            this.redirectMain();
          }, 2000);
        },
        (error) => {
          this.showProgress = false;
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'No se pudo registrar la nómina.'
          });
        }
      );
    }
    else {
      this.messageService.add({
        summary: 'Atención',
        detail: 'No hay ningun elemento de nómina para registrar.',
        severity: 'warn'
      });
    }
  }

  redirectMain() {
    let profile = this.getProfile() as ProfileC;
    this.screenModeService.screenMode.next(OperationMode.SELECTED);
    this.router.navigate(["nomina-manual-main"], {
      queryParams: {
        "e": profile.empresa,
        "a": profile.aplicacion,
        "s": profile.sesion,
        "idNomina": this.idNominaGeneral
      }
    });
  }

}
