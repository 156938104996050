import { Injectable } from '@angular/core';
import { ARestClient, RestBasicPaths } from '@axks/net';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NominaCatRestcService extends ARestClient {

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  getBaseEndpoint(): string {
    let path = environment.msNominaCat;
    if (!path.endsWith("/")) {
      path += "/";
    }
    return path;
  }

  processBody(res: any) {
    return res || {};
  }

  getRestPaths(): RestBasicPaths {
    let paths = super.getRestPaths();
    paths.detail = "";

    return paths;
  }
}
