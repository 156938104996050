<div class="progress container" *ngIf="!loadContent || showProgress">
    <p-progressSpinner class="progress-spinner" styleClass="progress-spinner axks-progress-spinner"
        strokeWidth="8"></p-progressSpinner>
</div>

<ng-container *ngIf="loadContent">

    <!-- {{item | json}} -->
    <axks-operations-bar (addNew)="addNew($event)" addNewLabel="Registrar" [addNewButton]="true" searchLabel="Buscar"
        [searchButton]="true" [sectionTitle]="getComponentTitle()">
    </axks-operations-bar>

    <axks-detail-tab *ngIf="showDetail()" [data]="workItem()" [colsDefinition]="colDefinition"
        [detailFields]="detailFields">
    </axks-detail-tab>

    <axks-cuenta-form #cmpForm [item]="workItem()" [formSearch]="formSearch" [formFields]="formFields"
        [operationMode]="operationMode" [class]="'cmp-form-' + operationMode"> </axks-cuenta-form>

    <axks-actions-bar (save)="save($event)" (cancel)="cancel($event)" (clean)="clean($event)" (find)="find($event)"
        (print)="print($event)" (edit)="edit($event)" (delete)="delete($event)" (csvFile)="csvFile($event)"
        (pdfFile)="pdfFile($event)" (txtFile)="txtFile($event)" saveLabel="Guardar" cancelLabel="Cancelar"
        cleanLabel="Limpiar" [printButton]="false">
    </axks-actions-bar>

    <axks-result-list [data]="resultList" [colsDefinition]="colDefinition" (rowSelect)="rowSelect($event)"
        (rowUnselect)="rowUnselect($event)" [listFields]="listFields" *ngIf="showResultList()">
    </axks-result-list>

    <p-toast></p-toast>
</ng-container>