import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseProcess } from '@axks/components';
import { BancoReceptorRestcService } from 'src/app/client/catalogos/banco-receptor/banco-receptor-restc.service';

@Injectable({
  providedIn: 'root'
})
export class BancoReceptorPsService extends BaseProcess<BancoReceptorRestcService>{

  constructor(httpClient: HttpClient, crest: BancoReceptorRestcService) { 
    super(httpClient, crest);
  }
}
