import { DatePipe, formatCurrency } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseMainComponent, ColumnDefinition, ComponentItem, OperationMode, OperationScreenModeService, SessionClientProvider, TabDefinition } from '@axks/components';
import { ProfileC } from '@axks/components/lib/api/views/profile';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { NominaManualCat } from 'src/app/api/nomina-manual/nomina-manual';
import { NominaManualCatPsService } from '../../../../procs/nomina-manual/nomina-manual-cat-ps.service';

@Component({
  selector: 'axks-nomina-manual-main',
  templateUrl: './nomina-manual-main.component.html',
  styleUrls: ['./nomina-manual-main.component.scss']
})
export class NominaManualMainComponent extends BaseMainComponent<NominaManualCatPsService> implements OnInit {

  tabDefs: TabDefinition[];
  urlParams = new URLSearchParams(window.location.search);
  redirect: boolean = false;
  mostrarDetalle: boolean = false;

  constructor(protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected screenModeService: OperationScreenModeService,
    protected sessionProvier: SessionClientProvider,
    private clientNomina: NominaManualCatPsService,
    private router: Router) {
    super(deviceService, messageService, screenModeService, sessionProvier);

    if (this.urlParams.get('idNomina')) {
      this.redirect = true;
    }
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.initTabMenu();
    if (this.redirect == true) {
      setTimeout(() => {
        this.initDetalle();
      }, 1);
    }
  }

  initEmptyItem(): ComponentItem {
    let empty = {
      item: {} as NominaManualCat,
      minimumItem: {} as NominaManualCat,
      registerItem: {} as NominaManualCat
    } as ComponentItem;
    return empty;
  }

  getService(): NominaManualCatPsService {
    return this.clientNomina;
  }

  initTabMenu() {
    this.tabDefs = [
      {
        groupId: "Nóminas Empleados",
        showAsTable: true
      }
    ] as TabDefinition[];
  }

  getColListDefinition(): ColumnDefinition[] {
    let cols = [
      // Datos generales de la nomina
      { field: 'id', header: 'ID Nómina', inAll: true, groupId: 'Datos generales' },
      { field: 'montoTotal', header: 'Monto total', inAll: true, groupId: 'Datos generales', formatFunction: this.formatoMoneda },
      { field: 'fechaNomina', header: 'Fecha de registro', inAll: true, formatFunction: this.formatoFecha, groupId: 'Datos generales' },
      { field: 'nombreEmpresa', header: 'Empresa', inAll: true, groupId: 'Datos generales' },
      { field: 'razonSocial', header: 'Razón Social', inDetail: true, groupId: 'Datos generales' },
      { field: 'rfc', header: 'RFC', inDetail: true, groupId: 'Datos generales' },

      // Lista de nominas de empleados
      { field: 'key.idNominaManualProg.nombreEmpleado', header: 'Nombre Empleado', arrayFieldName: 'nominas', inDetail: true, groupId: 'Nóminas Empleados' },
      { field: 'key.idNominaManualProg.tipoCuenta', header: 'Tipo de cuenta', arrayFieldName: 'nominas', inDetail: true, groupId: 'Nóminas Empleados' },
      { field: 'key.idNominaManualProg.cuentaAbono', header: 'Cuenta', arrayFieldName: 'nominas', inDetail: true, groupId: 'Nóminas Empleados' },
      { field: 'importe', header: 'Importe pagado', arrayFieldName: 'nominas', inDetail: true, groupId: 'Nóminas Empleados', formatFunction: this.formatoMoneda },
      { field: 'key.idNominaManualProg.idBancoReceptor.bancoReceptor', header: 'Banco receptor', arrayFieldName: 'nominas', inDetail: true, groupId: 'Nóminas Empleados' },
      { field: 'key.idNominaManualProg.plazaBanxico', header: 'Plaza Banxico', arrayFieldName: 'nominas', inDetail: true, groupId: 'Nóminas Empleados' },
      { field: 'fecAutoriza', header: 'Fecha autorización', arrayFieldName: 'nominas', inDetail: true, groupId: 'Nóminas Empleados', formatFunction: this.formatoFechaHora }
    ] as ColumnDefinition[];
    return cols;
  }

  getIdOfSelectedItem() {
    return this.item.minimumItem.id || this.item.item.id;
  }

  getComponentTitle(): string {
    return 'Nómina Manual';
  }

  addNew(event: any): void {
    super.addNew(event);
    let profile = this.getProfile() as ProfileC;
    this.screenModeService.screenMode.next(OperationMode.ADDNEW);
    this.router.navigate(["nomina"], {
      queryParams: {
        "e": profile.empresa,
        "a": profile.aplicacion,
        "s": profile.sesion,
        "nomina-manual": true
      }
    });
  }

  formatoMoneda(monto: number): string {
    return formatCurrency(monto, "es-MX", "$ ", "MXN");
  }

  formatoFecha(value: string): string {
    let pipe = new DatePipe("es-MX");
    return pipe.transform(value, "dd 'de' MMMM 'de' yyyy", "America/Mexico_City");
  }

  formatoFechaHora(value: string): string {
    let pipe = new DatePipe("es-MX");
    return pipe.transform(value, "dd 'de' MMMM 'de' yyyy - hh:mm:ss a", "America/Mexico_City");
  }

  initDetalle() {
    this.screenModeService.screenMode.next(OperationMode.SELECTED);
    let itemMin = {
      id: parseInt(this.urlParams.get('idNomina'))
    } as NominaManualCat;
    this.rowSelect(itemMin);
    this.mostrarDetalle = true;
  }

  cancel(event: any): void {
    this.mostrarDetalle = false;
    super.cancel(event);
    this.urlParams.delete('idNomina');
    window.history.replaceState(null, document.title, window.location.origin + window.location.pathname + '?' + this.urlParams.toString())
  }


}
