import { Component, OnInit } from '@angular/core';
import { BaseMainComponent, ColumnDefinition, ComponentItem, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { NominaManualProg } from 'src/app/api/nomina-manual/nomina-manual';
import { NominaManualProgPsService } from '../../../../procs/nomina-manual-prog/nomina-manual-prog-ps.service';
import { NominaManualProgReg } from '../../../../api/nomina-manual/nomina-manual';
import { BancoReceptor } from '../../../../api/catalogos/banco-receptor';
import { formatCurrency } from '@angular/common';
import { PeriodoPago } from '../../../../api/catalogos/periodo-pago';

@Component({
  selector: 'axks-nomina-manual-prog',
  templateUrl: './nomina-manual-prog.component.html',
  styleUrls: ['./nomina-manual-prog.component.scss']
})
export class NominaManualProgComponent extends BaseMainComponent<NominaManualProgPsService> implements OnInit {

  constructor(protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected screenModeService: OperationScreenModeService,
    protected sessionProvier: SessionClientProvider,
    private client: NominaManualProgPsService) {

    super(deviceService, messageService, screenModeService, sessionProvier);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.find(this.item.item);
  }

  initEmptyItem(): ComponentItem {
    let empty = {
      item: {
        idBancoReceptor: {} as BancoReceptor,
        idPeriodo: {} as PeriodoPago,
        activa: true
      } as NominaManualProg,
      minimumItem: {
        idBancoReceptor: {} as BancoReceptor,
        idPeriodo: {} as PeriodoPago,
        activa: true
      } as NominaManualProg,
      registerItem: {
        idBancoReceptor: {} as BancoReceptor,
        idPeriodo: {} as PeriodoPago,
        idUserReg: this.profile.idEmpleado,
        activa: true
      } as NominaManualProgReg
    } as ComponentItem;
    return empty;
  }

  getService(): NominaManualProgPsService {
    return this.client;
  }

  getColListDefinition(): ColumnDefinition[] {
    let cols = [
      { field: 'id', header: 'Núm. Registro', inAll: true },
      { field: 'nombreEmpleado', header: 'Nombre del empleado', inAll: true },
      { field: 'tipoCuenta', header: 'Tipo de cuenta', inAll: true },
      { field: 'cuentaAbono', header: 'Cuenta', inAll: true },
      { field: 'importe', header: 'Importe a pagar', inAll: true, formatFunction: this.formatoMoneda },
      { field: 'idPeriodo.periodoPago', header: 'Periodo de pago', inAll: true },
      { field: 'idBancoReceptor.bancoReceptor', header: 'Banco receptor', inAll: true },
      { field: 'plazaBanxico', header: 'Plaza Banxico', inAll: true }
    ] as ColumnDefinition[];
    return cols;
  }

  getIdOfSelectedItem() {
    return this.item.minimumItem.id || this.item.item.id;
  }

  getComponentTitle(): string {
    return 'Programación Nómina Manual'
  }

  formatoMoneda(value: any) {
    return formatCurrency(value, 'es-MX', '$');
  }

  find(event: any): void {
    super.find(event);
  }

}
