import { Injectable } from '@angular/core';
import { ARestClient, RestBasicPaths } from '@axks/net';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { CuentaEmpleadoKey, CuentaEmpleado } from 'src/app/api/cuenta-empleado/cuenta-empleado';
import { MessageService } from 'primeng/api';
import { Message } from '@axks/components';

@Injectable({
  providedIn: 'root'
})
export class CuentaEmpleadoRestcService extends ARestClient {

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  getBaseEndpoint(): string {
    let path = environment.msCuentaEmpleado;
    if (!path.endsWith("/")) {
      path += "/";
    }

    return path;
  }

  processBody(res: any) {
    return res || {};
  }

  getRestPaths(): RestBasicPaths {
    let paths = super.getRestPaths();
    paths.detail = "detail";
    paths.remove = "remove"

    return paths;
  }  

  detail<CuentaEmpleadoKey, CuentaEmpleado>(id: CuentaEmpleadoKey): Observable<CuentaEmpleado> {
    let path = this.getBaseEndpoint() + this.getRestPaths().detail;
    if (!path.endsWith("/")) {
      path += "/";
    }

    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");
    return this.httpClient.post(path, id, { headers }) as Observable<CuentaEmpleado>;

  }

  removeElement<CuentaEmpleadoKey, Message>(id: any): Observable<Message> {
    let path = this.getBaseEndpoint() + this.getRestPaths().remove + "?idCuenta=" + id.idCuentaEmp + "&idEmpleado=" + id.idEmpleado;

    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");
    return this.httpClient.delete(path, { headers }) as Observable<Message>;

  }
}
