import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseProcess } from '@axks/components';
import { TipoCuentaRestcService } from 'src/app/client/catalogos/tipo-cuenta/tipo-cuenta-restc.service';

@Injectable({
  providedIn: 'root'
})
export class TipoCuentaPsService extends BaseProcess<TipoCuentaRestcService>{

  constructor(httpClient: HttpClient, crest: TipoCuentaRestcService) {
    super(httpClient, crest);
   }
}
