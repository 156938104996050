import { Injectable } from '@angular/core';
import { ARestClient, RestBasicPaths } from '@axks/net';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Nomina, NominaKey } from '../../api/nomina/nomina';

@Injectable({
  providedIn: 'root'
})
export class NominaRestcService extends ARestClient {

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  getBaseEndpoint(): string {
    let path = environment.msNomina;
    if (!path.endsWith("/")) {
      path += "/";
    }
    return path;
  }
  processBody(res: any) {
    return res || {};
  }

  getRestPaths(): RestBasicPaths {
    let pahts = super.getRestPaths();
    pahts.detail = "findById";
    return pahts;
  }

  detail<Nomina, NominaKey>(id: NominaKey): Observable<Nomina> {
    let path = this.getBaseEndpoint() + this.getRestPaths().detail;
    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");
    return this.httpClient.post(path, id, { headers }) as Observable<Nomina>;
  }

  removeElement<NominaKey, Message>(id: any): Observable<Message> {
    let path = this.getBaseEndpoint() + this.getRestPaths().remove + "?idNomina=" + id.idNomina.id + "&idEmpleado=" + id.idEmpleado;

    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");
    return this.httpClient.delete(path, { headers }) as Observable<Message>;

  }

  cargarDatosNomina(idPeriodo: number): Observable<Nomina[]> {
    let path = this.getBaseEndpoint() + "cargar-datos-nomina/" + idPeriodo;

    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");
    return this.httpClient.get(path, { headers }) as Observable<Nomina[]>;
  }

  registrarNomina(items: Nomina[], idEmpresa: number): Observable<Nomina[]> {
    let path = this.getBaseEndpoint() + "registrar-nomina?idEmpresa=" + idEmpresa;

    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");

    return this.httpClient.post(path, items, { headers }) as Observable<Nomina[]>;
  }
}
