import { Injectable } from '@angular/core';
import { BaseProcess } from '@axks/components'
import { HttpClient } from '@angular/common/http';
import { CuentaEmpleadoRestcService } from '../../client/cuenta-empleado/cuenta-empleado-restc.service';

@Injectable({
  providedIn: 'root'
})
export class CuentaEmpleadoPsService extends BaseProcess<CuentaEmpleadoRestcService>{

  constructor(httpClient: HttpClient, restc: CuentaEmpleadoRestcService) { 
    super(httpClient,restc);
  }
}
