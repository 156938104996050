<div class="p-grid frm-part">
    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-3 p-col-12">
        <span class="p-float-label">
            <p-dropdown inputId="fl-emp" [(ngModel)]="item.idEmpresa" [options]="catEmpresas" optionLabel="nombre"
                optionValue="id" [autoDisplayFirst]="false" autoWidth="true" [style]="{'width':'80%'}" showClear="true">
            </p-dropdown>
            <label for="fl-emp" class="label-full">Empresa</label>
        </span>
    </div>
    <div class="p-sm-12 p-md-6 p-lg-4 p-xl-3 p-col-12">
        <span class="p-float-label">
            <p-calendar [(ngModel)]="item.fechaNomina" [showIcon]="true" inputId="icon" dateFormat="yy/mm/dd"
                dataType="string" [yearNavigator]="true" [yearRange]="yearRange()"></p-calendar>
            <label for="fl-fecNom" class="label-full">Fecha de nómina</label>
        </span>
    </div>
</div>