import { Component, OnInit, Input } from '@angular/core';
import { CuentaEmpleado } from '../../../api/cuenta-empleado/cuenta-empleado';
import { ProfileC } from '@axks/components/lib/api/views/profile';
import { BaseView, OperationScreenModeService, SessionClientProvider, OperationMode } from '@axks/components';
import { BancoReceptor } from '../../../api/catalogos/banco-receptor';
import { TipoCuenta } from '../../../api/catalogos/tipo-cuenta';
import { EmpleadoNombre } from '../../../api/empleado/empleado';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { BancoReceptorPsService } from '../../../procs/catalogos/banco-receptor/banco-receptor-ps.service';
import { TipoCuentaPsService } from '../../../procs/catalogos/tipo-cuenta/tipo-cuenta-ps.service';
import { EmpleadoPsService } from '../../../procs/empleado/empleado-ps.service';

@Component({
  selector: 'axks-cuenta-form',
  templateUrl: './cuenta-form.component.html',
  styleUrls: ['./cuenta-form.component.scss']
})
export class CuentaFormComponent extends BaseView implements OnInit {

  @Input() item: CuentaEmpleado;
  @Input() profile = this.getProfile() as ProfileC;

  catBancoReceptor: BancoReceptor[];
  catTipoCuenta: TipoCuenta[];
  catEmpleados: EmpleadoNombre[];

  op = OperationMode;

  constructor(protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected screenModeService: OperationScreenModeService,
    protected sessionProvier: SessionClientProvider,
    private clientBancRecep: BancoReceptorPsService,
    private clientTipoCuenta: TipoCuentaPsService,
    private clientEmpleado: EmpleadoPsService) {
    super(screenModeService, deviceService, messageService, sessionProvier);
  }
  ngOnInit(): void {
    if (!this.item) {
      this.item = {
        key: {},
        idTipoCuenta: {},
        idBancoReceptor: {}
      };
    }

    this.initCatBancoReceptor();
    this.initCatTipoCuenta();
    this.initCatEmpleados();
  }

  initCatBancoReceptor() {
    let filtro = {} as BancoReceptor;
    this.clientBancRecep.find(filtro).subscribe(
      (data) => {
        this.catBancoReceptor = data;
      }
    );

    this.item.key?.idEmpleado;
  }

  initCatTipoCuenta() {
    let filtro = {} as TipoCuenta;
    this.clientTipoCuenta.find(filtro).subscribe(
      (data) => {
        this.catTipoCuenta = data;
      }
    );
  }

  initCatEmpleados() {
    this.clientEmpleado.empleadosContratoActivo(this.profile.empresa).subscribe(
      (data) => {
        this.catEmpleados = data;
      }
    );
  }

}
