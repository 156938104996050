import { Component, OnInit } from '@angular/core';
import { BaseMainComponent, ColumnDefinition, ComponentItem, OperationMode, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { CuentaEmpleadoPsService } from '../../../procs/cuenta/cuenta-empleado-ps.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { CuentaEmpleadoKey, CuentaEmpleado } from '../../../api/cuenta-empleado/cuenta-empleado';
import { TipoCuenta } from '../../../api/catalogos/tipo-cuenta';
import { BancoReceptor } from '../../../api/catalogos/banco-receptor';

@Component({
  selector: 'axks-cuenta',
  templateUrl: './cuenta.component.html',
  styleUrls: ['./cuenta.component.scss']
})
export class CuentaComponent extends BaseMainComponent<CuentaEmpleadoPsService> implements OnInit {

  constructor(protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected screenModeService: OperationScreenModeService,
    protected sessionProvier: SessionClientProvider,
    private cuentaPs: CuentaEmpleadoPsService) {
    super(deviceService, messageService, screenModeService, sessionProvier);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  initEmptyItem(): ComponentItem {
    let empty = {
      item: {
        key: {} as CuentaEmpleadoKey,
        idTipoCuenta: {} as TipoCuenta,
        idBancoReceptor: {} as BancoReceptor
      } as CuentaEmpleado,

      registerItem: {
        key: {} as CuentaEmpleadoKey,
        idTipoCuenta: {} as TipoCuenta,
        idBancoReceptor: {} as BancoReceptor,
      } as CuentaEmpleado,

      minimumItem: {
        key: {} as CuentaEmpleadoKey,
        idTipoCuenta: {} as TipoCuenta,
        idBancoReceptor: {} as BancoReceptor,
      } as CuentaEmpleado,

    } as ComponentItem;

    return empty;
  }

  getService(): CuentaEmpleadoPsService {
    return this.cuentaPs;
  }

  getColListDefinition(): ColumnDefinition[] {
    return [
      { header: 'ID Cuenta', field: 'key.idCuentaEmp', inDetail: true },
      { header: 'Núm. Empleado', field: 'key.idEmpleado', inDetail: true },
      { header: 'Empleado', field: 'nombreEmpleado', inAll: true },
      { header: 'Núm. Cuenta', field: 'numeroCuenta', inAll: true },
      { header: 'Tipo de cuenta', field: 'idTipoCuenta.tipoCuenta', inAll: true },
      { header: 'Banco receptor', field: 'idBancoReceptor.bancoReceptor', inAll: true },
      { header: 'Estatus', field: 'activa', inAll: true, formatFunction: this.formatoEsNomina },
    ] as ColumnDefinition[];
  }

  getIdOfSelectedItem(): CuentaEmpleadoKey {
    return this.item.minimumItem.key || this.item.item.key;
  }
  getComponentTitle(): string {
    return "Cuentas bancarias";
  }

  formatoEsNomina(item: any): string {
    if (item !== undefined && item !== "" && item !== null) {
      return (item == true ? 'Activa' : 'Inactiva');
    }
    else {
      return '';
    }
  }

  save(event: any): void {
    this.cuentaPs.register(this.item.registerItem).subscribe(
      (data) => {
        this.screenModeService.screenMode.next(OperationMode.SELECTED);
        super.rowSelect(data);
        this.showDetail();
      },
      (error) => {
        this.errorMessage("Error", "No se pudo registrar el elemento");
      }
    );
  }
}
