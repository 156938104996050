import { Injectable } from '@angular/core';
import { BaseProcess } from '@axks/components';
import { HttpClient } from '@angular/common/http';
import { NominaManualRestcService } from '../../client/nomina-manual/nomina-manual-restc.service';
import { NominaManualProg, NominaManual } from '../../api/nomina-manual/nomina-manual';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NominaManualPsService extends BaseProcess<NominaManualRestcService> {

  client: NominaManualRestcService;

  constructor(httpClient: HttpClient, crest: NominaManualRestcService) {
    super(httpClient, crest);

    this.client = crest;
  }

  registrarNomina(items: NominaManualProg[], idEmpresa: number, idUserEmp: number): Observable<NominaManual[]> {
    return this.client.registrarNomina(items, idEmpresa, idUserEmp);
  }
}
