import { Injectable } from '@angular/core';
import { BaseProcess } from '@axks/components';
import { EmpresaRestcService } from '../../client/empresa/empresa-restc.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EmpresaPsService extends BaseProcess<EmpresaRestcService> {

  constructor(httpClient: HttpClient, crest: EmpresaRestcService) {
    super(httpClient, crest);
  }
}
