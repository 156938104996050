import { Injectable } from '@angular/core';
import { ARestClient } from '@axks/net';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { NominaManualProg, NominaManual } from '../../api/nomina-manual/nomina-manual';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NominaManualRestcService extends ARestClient {

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  getBaseEndpoint(): string {
    let path = environment.msNominaManual;

    if (!path.endsWith("/")) {
      path += "/";
    }
    return path;
  }

  processBody(res: any) {
    return res || {};
  }

  registrarNomina(items: NominaManualProg[], idEmpresa: number, idUserEmp: number): Observable<NominaManual[]> {
    let path = this.getBaseEndpoint() + this.getRestPaths().add + "?idEmpresa=" + idEmpresa + "&idUserEmp=" + idUserEmp;

    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");

    return this.httpClient.post(path, items, { headers }) as Observable<NominaManual[]>;
  }

  detail<NominaManual, NominaKey>(id: NominaKey): Observable<NominaManual> {
    let path = this.getBaseEndpoint() + this.getRestPaths().detail;
    const headers = new HttpHeaders()
      .set('Access-Control-Allow-Origin', "*")
      .set('Access-Control-Allow-Headers', "*");
    return this.httpClient.post(path, id, { headers }) as Observable<NominaManual>;
  }
}
