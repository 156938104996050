import { Injectable } from '@angular/core';
import { BaseProcess } from '@axks/components';
import { NominaManualProgRestcService } from 'src/app/client/nomina-manual-prog/nomina-manual-prog-restc.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NominaManualProgPsService extends BaseProcess<NominaManualProgRestcService>{

  constructor(httpClient: HttpClient, crest: NominaManualProgRestcService) {
    super(httpClient, crest);
  }
}
