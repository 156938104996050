import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseProcess } from '@axks/components';
import { PeriodoPagoRestcService } from 'src/app/client/catalogos/periodo-pago/periodo-pago-restc.service';

@Injectable({
  providedIn: 'root'
})
export class PeriodoPagoPsService extends BaseProcess<PeriodoPagoRestcService>{

  constructor(httpClient: HttpClient, crest: PeriodoPagoRestcService) { 
    super(httpClient, crest);
  }
}
