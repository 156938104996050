import { Component, Input, OnInit } from '@angular/core';
import { BaseView, OperationScreenModeService, SessionClientProvider } from '@axks/components';
import { Empresa } from 'src/app/api/empresa/empresa';
import { NominaManualCat } from 'src/app/api/nomina-manual/nomina-manual';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MessageService } from 'primeng/api';
import { EmpresaPsService } from 'src/app/procs/empresa/empresa-ps.service';

@Component({
  selector: 'axks-nomina-manual-form',
  templateUrl: './nomina-manual-form.component.html',
  styleUrls: ['./nomina-manual-form.component.scss']
})
export class NominaManualFormComponent extends BaseView implements OnInit {

  @Input() item: NominaManualCat;

  catEmpresas: Empresa[];

  constructor(protected screenModeService: OperationScreenModeService,
    protected deviceService: DeviceDetectorService,
    protected messageService: MessageService,
    protected sessionProvier: SessionClientProvider,
    private clientEmpresa: EmpresaPsService) {
    super(screenModeService, deviceService, messageService, sessionProvier);
  }

  ngOnInit(): void {
    if(!this.item){
      this.item = {};
    }

    this.initCatEmpresas();
  }

  initCatEmpresas(){
    let filtro = {
      esCliente: false
    } as Empresa;
    this.clientEmpresa.find(filtro).subscribe(
      (data) => {
        this.catEmpresas = data;
      },
      (error) => {
        this.messageService.add({
          summary: 'Error',
          detail: 'No se pudo obtener el catalogo de empresas',
          severity: 'error'
        });
      }
    );
  }

  yearRange(): string {
    let now = new Date();
    let anioActual = now.getFullYear();
    return '2011:'+anioActual;
  }

}
