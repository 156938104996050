<div class="progress container" *ngIf="showProgress">
    <p-progressSpinner class="progress-spinner" styleClass="progress-spinner axks-progress-spinner"
        strokeWidth="8"></p-progressSpinner>
</div>

<p-confirmDialog></p-confirmDialog>

<div>
    <!-- {{selNomina | json}} -->
    <p-table [value]="datosNomina" [(selection)]="selNomina" selectionMode="single" [resizableColumns]="true"
        (onRowSelect)="seleccionaRegistro($event)" class="result-list result"
        (onRowUnselect)="quitarValorSeleccionado($event)" [paginator]="true" [rows]="25" [showCurrentPageReport]="true"
        currentPageReportTemplate="{first} - {last} de {totalRecords}"
        [rowsPerPageOptions]="[25,50,100,{showAll:'Todos'}]">

        <ng-template pTemplate="caption">
            <div class="p-d-flex">
                <button type="button" pButton pRipple icon="pi pi-save" label="Autorizar nómina"
                    (click)="autorizarNomina()" class="p-mr-2" [disabled]="showProgress"></button>
                <button type="button" pButton icon="pi pi-file-excel" iconPos="left" pTooltip="Generar excel"
                    (click)="exportExcel()" style="margin-right: 0.5em;" class="p-button-success p-ml-auto"></button>
            </div>
        </ng-template>

        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="key.idEmpleado">Núm. Empleado <p-sortIcon field="key.idEmpleado"></p-sortIcon>
                </th>
                <th pSortableColumn="apellidoPaterno">Apellido paterno <p-sortIcon field="apellidoPaterno"></p-sortIcon>
                </th>
                <th pSortableColumn="apellidoMaterno">Apellido materno <p-sortIcon field="apellidoMaterno"></p-sortIcon>
                </th>
                <th pSortableColumn="nombre">Nombre <p-sortIcon field="nombre"></p-sortIcon>
                </th>
                <th pSortableColumn="cuentaAbono">Cuenta <p-sortIcon field="cuentaAbono"></p-sortIcon>
                </th>
                <th pSortableColumn="importePago">Importe <p-sortIcon field="importePago"></p-sortIcon>
                </th>
                <th pSortableColumn="conceptoPago">Id Concepto <p-sortIcon field="conceptoPago"></p-sortIcon>
                </th>
            </tr>

            <tr>
                <th colspan="5" class="p-text-right">Total</th>
                <th>{{sumadoTotal | currency}}</th>
                <th colspan="1"></th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-itemNomina>
            <tr [pSelectableRow]="itemNomina">
                <td>
                    <span class="p-column-title">Núm. Empleado</span>
                    {{itemNomina.key.idEmpleado}}
                </td>
                <td>
                    <span class="p-column-title">Apellido paterno</span>
                    {{itemNomina.apellidoPaterno}}
                </td>
                <td>
                    <span class="p-column-title">Apellido materno</span>
                    {{itemNomina.apellidoMaterno}}
                </td>
                <td>
                    <span class="p-column-title">Nombre</span>
                    {{itemNomina.nombre}}
                </td>
                <td>
                    <span class="p-column-title">Cuenta</span>
                    {{itemNomina.cuentaAbono}}
                </td>
                <td>
                    <span class="p-column-title">Importe</span>
                    {{itemNomina.importePago | currency}}
                </td>
                <td>
                    <span class="p-column-title">Id concepto</span>
                    {{itemNomina.conceptoPago}}
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>

<p-toast></p-toast>

<div>
    <p-dialog header="Detalle de nomina" [(visible)]="visibleDialog" [maximizable]="true" [modal]="true"
        [baseZIndex]="10000" contentStyleClass="dialog-content-height">

        <div class="p-grid frm-resume" style="padding-left: 15px; padding-right: 40px;">
            <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12">
                <h3>Empleado: <span style="font-weight:normal">{{selNomina.nombre}}
                        {{selNomina.apellidoPaterno}} {{selNomina.apellidoMaterno}}</span></h3>
                <h3 *ngIf="idPeriodo != PR_PAGO.SEMANAL">Sueldo mensual: <span style="font-weight:normal">{{selNomina.sueldo | currency}}</span></h3>
                <h3 *ngIf="idPeriodo == PR_PAGO.SEMANAL">Sueldo semanal: <span style="font-weight:normal">{{selNomina.sueldo | currency}}</span></h3>
            </div>
            <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12">
                <br>
                <div class="wz-preview-label">Número de empleado</div><br>
                <div class="wz-preview-value">{{selNomina.key.idEmpleado}}</div>
            </div>
            <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12">
                <br>
                <div class="wz-preview-label">Importe Pago</div><br>
                <div class="wz-preview-value">{{selNomina.importePago | currency}}</div>
            </div>
            <div class="p-sm-12 p-md-6 p-lg-4 p-xl-4 p-col-12">
                <br>
                <div class="wz-preview-label">ID Concepto</div><br>
                <div class="wz-preview-value">{{selNomina.conceptoPago}}</div>
            </div>
        </div>
        <div *ngIf="selNomina.descuentos.length > 0" class="p-grid frm-resume" style="padding-left: 15px; padding-right: 40px;">
            <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 p-col-12">
                <h3>Descuentos</h3>
            </div>
            <ng-container *ngFor="let item of selNomina.descuentos">
                <div class="p-sm-12 p-md-6 p-lg-3 p-xl-3 p-col-12">
                    <div class="wz-preview-label">{{item.idTipoDescuento.tipoDescuento}}</div><br>
                    <div class="wz-preview-value">-{{item.monto | currency}}</div>
                </div>
            </ng-container>
        </div>
        <!-- <p-footer>
            <div
                [style]="{'justify-content': 'right', 'display': 'flex', 'margin-top': '50px', 'padding-bottom': '30px'}">
                <p-button type="button" (click)="visibleDialog = false" label="Guardar" icon="pi pi-check">
                </p-button>&nbsp;&nbsp;
            </div>
        </p-footer> -->
    </p-dialog>
</div>